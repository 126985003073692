.steps {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  font-size: 1rem;
  font-weight: 300;
}

.steps .step-number {
  box-sizing: border-box;
  color: #383838;
  background-color: #fff;
  padding: 1.2rem;
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.steps .chosen-number {
  background-color: #383838;
  color: #e0e0e0;
}

.steps .not-allowed {
  opacity: .5;
  cursor: not-allowed;
}
