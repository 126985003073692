.pulse-loader div {
  display: inline-flex;
  width: 0.5rem;
  height: 0.5rem;
  background-color: #383838;
  border-radius: 100%;
  animation: pulse .4s ease alternate infinite;
}

.pulse-loader div:nth-child(0) {
  animation-delay: 0s;
}

.pulse-loader div:nth-child(1) {
  animation-delay: 0.2s;
}

.pulse-loader div:nth-child(2) {
  animation-delay: 0.4s;
}

.loader-container {
  z-index: 10;
  display: grid;
  place-content: center;
  width: 100%;
}

.loader-container .loader {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  align-items: center;
  pointer-events: none;
  user-select: none;
}

.loader-container .on-screen-center {
  position: fixed;
  top: 50%;
  left: 50%;
}

.add-to-cart-popup-pulse-loader-fade-enter-active,
.add-to-cart-popup-pulse-loader-fade-leave-active {
  transition: opacity 0.2s ease-out;
}
.add-to-cart-popup-pulse-loader-fade-enter,
.add-to-cart-popup-pulse-loader-fade-leave-to {
  opacity: 0;
}

@keyframes pulse {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0.25;
    transform: scale(0.5);
  }
}
