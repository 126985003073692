.important-message {
  background-color: #E7DC76;
  border-radius: 10px;
  width: 80%;
  margin: 0 auto;
  padding: 0.8em;
  text-transform: uppercase;
  color: #383838;
}

.header {
  font-family: 'Chronicle Display', serif;
  font-weight: 400;
  margin: 1.4rem 0;
}

.text-container {
  display: grid;
  gap: 1.4rem;
  line-height: 1.6;
  text-align: left;
  margin: 0 auto 1.6rem auto;
}

.firstpage-container {
  max-width: 342px;
  margin: 0 auto;
}

.firstpage-container button {
  padding: 1rem;
}
