.button {
  display: grid;
  grid-auto-flow: dense;
  grid-template-columns: 1fr auto 1fr;
  justify-content: space-between;
  align-items: center;
  outline-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  padding: .7em;
  border-radius: 5px;
  width: 100%;
  position: relative;
  text-decoration: none;
  white-space: nowrap;
}

.black {
  background-color: #383838;
  color: white;
}

.black img {
  height: 12px;
}

.black:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.outlined {
  border: 1px solid #383838;
  color: #383838;
  background-color: transparent;
}

.outlined img {
  height: 12px;
  transform: rotate(180deg);
}

.recalculate {
  border: none;
  border-bottom: 1px solid #383838;
  border-radius: 0;
  background-color: transparent;
  width: min-content;
  margin: 0 auto;
  padding: 0;
}

.centered > span {
  position: absolute;
  left: 50%;
}

.column-left {
  grid-column-start: 1;
  justify-self: start;
}

.column-center {
  grid-column-start: 2;
}

.column-right {
  grid-column-start: 3;
  justify-self: end;
  margin-left: .5rem;
}

.column-together {
  grid-column-start: 3;
  justify-self: start;
  margin-left: .5rem;
}
