.sizes {
  color: #383838;
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  font-weight: 600;
  justify-items: center;
  margin-top: 0.4rem;
  font-size: 1.6rem;
}

.checkmark {
  grid-column: 1/2;
  justify-self: right;
}

.fs-smaller {
  font-size: smaller;
}
