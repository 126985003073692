*,
*:after,
*:before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  background-color: #faf9f6;
  min-height: 100vh;
  min-width: 100%;
  height: 100%;
  -webkit-text-size-adjust: 100%;
  padding: 0;
  margin: 0;
  color: #383838;
}

a {
  color: inherit;
  text-decoration: none;
}
